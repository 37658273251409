import React from "react"

export default (props) => (
  <footer className="site-footer py-5">
    <a href="https://syukrieseo.com" target="_blank" rel="noopener noreferrer">
    </a>
    <p className="mt-1">
      &copy;{ new Date().getFullYear() } 
      <a href="https://syukrieseo.com" target="_blank" rel="noopener noreferrer"> SyukrieSEO.Com</a>,
      by Muhammad Syukri.
    </p>
  </footer>
)
